.home-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50vh;
  padding: 20px;

  .title-container,
  .sub-title-container {
    align-items: center;
    display: flex;
    justify-content: center;

    .title {
      font-size: 7.5em;
      font-weight: bold;
      min-width: 400px;
      width: 50%;
    }

    .sub-title {
      font-size: 3.4em;
      font-weight: bold;
      min-width: 400px;
      width: 50%;
    }
  
    .logo-container {
      min-width: 400px;
      width: 50%;

      .time-wasting-logo {
        height: auto;
        width: 100%;
      }
    }
  }

  .title-container {
    flex-wrap: wrap;
  }

  .sub-title-container {
    flex-wrap: wrap-reverse;

    &:nth-child(odd) {
      flex-wrap: wrap;
    }
  }

  .question-title {
    font-size: 6em;
    font-weight: bold;
    text-align: center;
  }

  .links-container {
    display: flex;
    flex-direction: column;
    font-size: 2.5em;

    .link-container {
      border: 1px solid #b1b1b1;
      margin-top: 5px;
      padding: 15px;

      .link {
        color: #0055AA;
        text-decoration: none;
        padding: 5px;
    
        &:hover {
          background-color: #0055AA;
          border-radius: 10px;
          color: #FFFFFF;
          font-weight: bold;
          padding: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .home-container {
    .title-container,
    .sub-title-container {
      .title {
        font-size: 6em;
        padding: 20px;
        width: 100%;
      }

      .sub-title {
        font-size: 3em;
        padding: 20px;
        width: 100%;
      }

      .logo-container {
        width: 100%;
      }
    }
  }
}
