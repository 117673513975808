.day-timer-container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 70vh;

  .timer {
    font-size: 3em;
    padding: 25px;
    text-align: center;
  }
}