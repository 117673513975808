.form-container {
  align-items: center;
  display: flex;
  flex-direction: column;

  .heading {
    font-size: 2em;
    font-weight: bold;
    padding: 15px;
    text-align: center;
  }

  .form-logo-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .mail-logo {
      height: auto;
      min-width: 400px;
      width: 30%;
    }

    .contact-us-form {
      display: flex;
      flex-direction: column;
      min-width: 300px;
      padding-top: 15px;
      width: 40%;
  
      label {
        font-size: 2em;
        font-weight: bold;
        padding-top: 10px;
      }
  
      input {
        font-size: 2em;
        font-family: 'Kodchasan', sans-serif;
      }
  
      button {
        background: none;
        border: 2px solid #0055AA;
        font-size: 2em;
        font-weight: bold;
        font-family: 'Kodchasan', sans-serif;
        margin-top: 15px;
        padding: 5px;
        transition: 0.3s;
  
        &:hover {
          background: #0055AA;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
  
      textarea {
        font-size: 2em;
        font-family: 'Kodchasan', sans-serif;
        height: 200px;
        resize: none;
      }
    }
  }

  
}