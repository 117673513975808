@import url('https://fonts.googleapis.com/css2?family=Kodchasan:wght@200;400;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  font-family: 'Kodchasan', sans-serif;
  font-size: 12px;
  margin: 0;
  min-height: 100vh;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 600px) {
  body {
    font-size: 10px;
  }
}