.advice-container {
  border: 1px solid #b1b1b1;
  margin: 10px;
  min-width: 300px;
  padding: 15px;
  transition: 0.2s;
  width: 50%;

  &:hover {
    padding: 20px;

    .title {
      font-size: 1.9em;
    }

    .text {
      font-size: 1.6em;
    }
  }

  .title {
    font-size: 1.7em;
    font-weight: bold;
  }

  .text {
    font-size: 1.4em;
  }
}