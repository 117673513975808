@import url(https://fonts.googleapis.com/css2?family=Kodchasan:wght@200;400;700&display=swap);
* {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  font-family: 'Kodchasan', sans-serif;
  font-size: 12px;
  margin: 0;
  min-height: 100vh;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 600px) {
  body {
    font-size: 10px;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home-container{align-items:center;display:flex;flex-direction:column;justify-content:center;min-height:50vh;padding:20px}.home-container .title-container,.home-container .sub-title-container{align-items:center;display:flex;justify-content:center}.home-container .title-container .title,.home-container .sub-title-container .title{font-size:7.5em;font-weight:bold;min-width:400px;width:50%}.home-container .title-container .sub-title,.home-container .sub-title-container .sub-title{font-size:3.4em;font-weight:bold;min-width:400px;width:50%}.home-container .title-container .logo-container,.home-container .sub-title-container .logo-container{min-width:400px;width:50%}.home-container .title-container .logo-container .time-wasting-logo,.home-container .sub-title-container .logo-container .time-wasting-logo{height:auto;width:100%}.home-container .title-container{flex-wrap:wrap}.home-container .sub-title-container{flex-wrap:wrap-reverse}.home-container .sub-title-container:nth-child(odd){flex-wrap:wrap}.home-container .question-title{font-size:6em;font-weight:bold;text-align:center}.home-container .links-container{display:flex;flex-direction:column;font-size:2.5em}.home-container .links-container .link-container{border:1px solid #b1b1b1;margin-top:5px;padding:15px}.home-container .links-container .link-container .link{color:#05a;text-decoration:none;padding:5px}.home-container .links-container .link-container .link:hover{background-color:#05a;border-radius:10px;color:#fff;font-weight:bold;padding:5px}@media screen and (max-width: 800px){.home-container .title-container .title,.home-container .sub-title-container .title{font-size:6em;padding:20px;width:100%}.home-container .title-container .sub-title,.home-container .sub-title-container .sub-title{font-size:3em;padding:20px;width:100%}.home-container .title-container .logo-container,.home-container .sub-title-container .logo-container{width:100%}}
.question-container{font-size:2em}.question-container .question-text{padding:10px}.question-container .radio-container{display:flex}.question-container .radio-container .radio{margin-right:25px}.question-container input{font-size:1em;font-family:"Kodchasan",sans-serif;margin-left:10px}
.questions-container{display:flex;justify-content:center}.questions-container .question-form{padding:5px}.questions-container .question-form input[type=submit]{background:none;border:2px solid #05a;font-size:2em;font-weight:bold;font-family:"Kodchasan",sans-serif;margin:15px 0 0 10px;padding:10px;transition:.3s}.questions-container .question-form input[type=submit]:hover{background:#05a;color:#fff;cursor:pointer}
.back-to-home-link-container{display:flex;justify-content:flex-end;padding:35px}.back-to-home-link-container .back-to-home-link{color:#05a;font-size:2em;padding:10px;text-decoration:none;transition:.2s}.back-to-home-link-container .back-to-home-link:hover{background:#05a;color:#fff}
.form-container{align-items:center;display:flex;flex-direction:column}.form-container .heading{font-size:2em;font-weight:bold;padding:15px;text-align:center}.form-container .form-logo-container{align-items:center;display:flex;flex-wrap:wrap;justify-content:center}.form-container .form-logo-container .mail-logo{height:auto;min-width:400px;width:30%}.form-container .form-logo-container .contact-us-form{display:flex;flex-direction:column;min-width:300px;padding-top:15px;width:40%}.form-container .form-logo-container .contact-us-form label{font-size:2em;font-weight:bold;padding-top:10px}.form-container .form-logo-container .contact-us-form input{font-size:2em;font-family:"Kodchasan",sans-serif}.form-container .form-logo-container .contact-us-form button{background:none;border:2px solid #05a;font-size:2em;font-weight:bold;font-family:"Kodchasan",sans-serif;margin-top:15px;padding:5px;transition:.3s}.form-container .form-logo-container .contact-us-form button:hover{background:#05a;color:#fff;cursor:pointer}.form-container .form-logo-container .contact-us-form textarea{font-size:2em;font-family:"Kodchasan",sans-serif;height:200px;resize:none}
.header{background:#05a;display:flex;justify-content:space-between;padding:15px;width:100%}.header .home-link{color:#fff;font-size:3em;font-weight:bold;text-decoration:none}.header .menu-button{background:none;border:none;color:#fff;cursor:pointer;font-size:2em;font-weight:bold;font-family:"Kodchasan",sans-serif;outline:none}@media screen and (max-width: 800px){.header .home-link{font-size:2em}.header .menu-button{font-size:1.5em}}
.test-result-container{align-items:center;display:flex;flex-direction:column;justify-content:center}.test-result-container .result-text{font-size:2.5em;font-weight:bold;padding:20px;text-align:center;width:70%}.test-result-container .result-logo{height:auto;min-width:400px;width:50%}
.overlay{height:100%;width:0;position:fixed;z-index:1;left:0;top:0;background-color:#000;background-color:rgba(0,0,0,.9);overflow-x:hidden;transition:.5s}.overlay .closebtn{background:none;border:none;color:#fff;cursor:pointer;position:absolute;top:20px;right:45px;font-size:60px;outline:none}.overlay a{padding:8px;text-decoration:none;font-size:36px;color:#818181;display:block;transition:.3s}.overlay a:hover,.overlay a:focus{color:#f1f1f1}.overlay-content{position:relative;top:25%;width:100%;text-align:center;margin-top:30px}@media screen and (max-height: 450px){.overlay a{font-size:20px}.overlay .closebtn{font-size:40px;top:15px;right:35px}}
.advice-container{border:1px solid #b1b1b1;margin:10px;min-width:300px;padding:15px;transition:.2s;width:50%}.advice-container:hover{padding:20px}.advice-container:hover .title{font-size:1.9em}.advice-container:hover .text{font-size:1.6em}.advice-container .title{font-size:1.7em;font-weight:bold}.advice-container .text{font-size:1.4em}
.advices-container{align-items:center;display:flex;flex-direction:column;justify-content:center;padding:15px}.advices-container .heading{font-size:2.2em;text-align:center}.advices-container .heading .link{color:#05a;text-decoration:none;padding:5px}.advices-container .heading .link:hover{background-color:#05a;border-radius:10px;color:#fff;font-weight:bold;padding:5px}
.day-timer-container{align-items:center;display:flex;justify-content:center;height:70vh}.day-timer-container .timer{font-size:3em;padding:25px;text-align:center}
