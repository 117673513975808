.questions-container {
  display: flex;
  justify-content: center;
  

  .question-form {
    padding: 5px;
    
    input[type=submit] {
      background: none;
      border: 2px solid #0055AA;
      font-size: 2em;
      font-weight: bold;
      font-family: 'Kodchasan', sans-serif;
      margin: 15px 0 0 10px;
      padding: 10px;
      transition: 0.3s;

      &:hover {
        background: #0055AA;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }
}