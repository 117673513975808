.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0, 0.9); 
  overflow-x: hidden;
  transition: 0.5s;

  .closebtn {
    background: none;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    outline: none;
  }

  a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block; 
    transition: 0.3s;

    &:hover, &:focus {
      color: #f1f1f1;
    }
  }
}

.overlay-content {
  position: relative;
  top: 25%; 
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

@media screen and (max-height: 450px) {
  .overlay {
    a {
      font-size: 20px
    }

    .closebtn {
      font-size: 40px;
      top: 15px;
      right: 35px;
    }
  }
}